export const BBC = [
  {
    id: "0",
    image: "../assets/imgs/bbc/janaceck.jpeg",
    title:
      "BBCSO rehearsals for Janacek Sinfonietta with Jayne Murrill and Gemma Riley",
  },
  { id: "1", image: "../assets/imgs/bbc/BBC2.jpg" },
  { id: "2", image: "../assets/imgs/bbc/BBC3.jpg" },
  { id: "3", image: "../assets/imgs/bbc/BBC4.jpg" },
  { id: "4", image: "../assets/imgs/bbc/BBC5.jpg" },
  { id: "4", image: "../assets/imgs/bbc/BBC6.jpg" },
  { id: "6", image: "../assets/imgs/bbc/BBC7.jpg" },
  { id: "7", image: "../assets/imgs/bbc/BBC8.jpg" },
  { id: "8", image: "../assets/imgs/bbc/BBC9.jpg" },
  { id: "9", image: "../assets/imgs/bbc/BBC10.jpg" },
  { id: "10", image: "../assets/imgs/bbc/BBC11.jpg" },
  { id: "11", image: "../assets/imgs/bbc/BBC12.jpg" },
  {
    id: "0",
    image: "../assets/imgs/bbc/BBC1.jpg",
    title: "First Night of the Proms 2021, socially distanced low brass!",
  },
  {
    id: "12",
    image: "../assets/imgs/bbc/BBC13.jpg",
    title: "First Night of the Proms 2021, socially distanced low brass!",
  },
  {
    id: "13",
    image: "../assets/imgs/bbc/BBC14.jpg",
    title:
      "Performing a duet with singer-songwriter Hak Baker at the Topping Out Ceremony, BBC's New Music Studio at East Bank, Stratford.",
  },
  {
    id: "14",
    image: "../assets/imgs/bbc/BBC15.jpg",
    title:
      "Performing a duet with singer-songwriter Hak Baker at the Topping Out Ceremony, BBC's New Music Studio at East Bank, Stratford.",
  },
  {
    id: "15",
    image: "../assets/imgs/bbc/BBC16.jpeg",
    title:
      "Performing a duet with singer-songwriter Hak Baker at the Topping Out Ceremony, BBC's New Music Studio at East Bank, Stratford.",
  },
  {
    id: "16",
    image: "../assets/imgs/bbc/BBC17.jpeg",
    title: "Aldeburgh Festival, Snape Maltings",
  },
  {
    id: "17",
    image: "../assets/imgs/bbc/BBC18.jpeg",
    title:
      "The Belshazzar's Feast Proms 2023.  Brass players - L to R: Jon Riches, Rob O'Neill, Dan Jenkins, Helen Vollam, Sam Elliott, Mark Templeton, Rochard Watckin, Gemma Riley, Stephen Calow, Josh Cirtina, Merin Rhyd, (Joe Arnold not pictured)",
  },
  {
    id: "18",
    image: "../assets/imgs/bbc/BBC19.jpeg",
    title: "First Night of the Proms 2023",
  },
  {
    id: "19",
    image: "../assets/imgs/bbc/BBC20.jpeg",
    title: "John Hopkins Prom",
  },
  {
    id: "20",
    image: "../assets/imgs/bbc/BBC21.WEBP",
    title: "BBC Proms Mahler 3, 2023",
  },
  {
    id: "21",
    image: "../assets/imgs/bbc/BBC22.jpeg",
    title: "BBC Proms Mahler 3, 2023",
  },
  {
    id: "21",
    image: "../assets/imgs/bbc/BBCSO trombones at the Barbican.jpeg",
    title: "BBCSO trombones at the Barbican",
  },
  {
    id: "21",
    image:
      "../assets/imgs/bbc/BBCSO trombones for Respighi's Pines of Rome.jpeg",
    title: "BBCSO trombones for Respighi's Pines of Rome",
  },
  {
    id: "21",
    image:
      "../assets/imgs/bbc/BBCSO low brass after performing Shostakovich Symphony No.11 with Jakub Hrusa at the Barbican.JPG",
    title:
      "BBCSO low brass after performing Shostakovich Symphony No.11 with Jakub Hrusa at the Barbican",
  },
];

export const HIGGINS = [
  { id: "0", image: "../assets/imgs/Higgins/higgins1.jpg", title: "" },
  { id: "1", image: "../assets/imgs/Higgins/higgins2.jpg", title: "" },
  { id: "2", image: "../assets/imgs/Higgins/higgins3.jpg", title: "" },
  { id: "3", image: "../assets/imgs/Higgins/higgins4.jpg", title: "" },
  { id: "4", image: "../assets/imgs/Higgins/higgins5.jpg", title: "" },
  {
    id: "5",
    image: "../assets/imgs/Higgins/higgins6.jpg",
    title: "rehearsals a BBC Maidavale Studios",
  },
];

export const OTHER = [
  {
    id: "1",
    image: "../assets/imgs/other/carnegie_billboard.jpeg",
    title: "Carnegie Hall Billboard.",
  },
  {
    id: "1",
    image: "../assets/imgs/other/jhollick.jpeg",
    title: "LSO USA tour, with Jonny Hollick at Carnegie Hall      .",
  },
  {
    id: "1",
    image: "../assets/imgs/other/LSO_usa.jpeg",
    title:
      "LSO low brass at Carnegie Hall, L to R: Ben Thomson, Paul Milner, Jonny Hollick, Merin Rhyd      ",
  },
  {
    id: "1",
    image: "../assets/imgs/other/songs_of_praise.jpeg",
    title:
      "Quintet for TV recording of Songs of Praise. L to R: James Pillai, Imogen Whitehead, Sasha Koushk-Jalali and Aaron Akugbo.",
  },
  {
    id: "1",
    image:
      "../assets/imgs/other/Septura at Barber Concerts, Elgar Concert Hall.JPG",
    title: "Septura at Barber Concerts, Elgar Concert Hall.",
  },
  {
    id: "1",
    image:
      "../assets/imgs/other/Septura at their 10th anniversary concert at the Wigmore Hall, with composer Roxanna Panufnik.jpeg",
    title:
      "Septura at their 10th anniversary concert at the Wigmore Hall, with composer Roxanna Panufnik.",
  },
  // {
  //   id: "1",
  //   image: "../assets/imgs/trio/other1.jpeg",
  //   title: "Musicians for The Coronation of King Charles, London 2023",
  // },
  {
    id: "1",
    image: "../assets/imgs/other/PJBE_comp.JPG",
    title:
      "Adjudicating the Philip Jones Brass Ensemble Competition at Trinity Laban. Photo with Ursula Jones and the prize winners.",
  },
  {
    id: "1",
    image: "../assets/imgs/other/LSO.JPG",
    title: "Guesting with the LSO for a Mahler 5 at the Barbican.",
  },
  {
    id: "1",
    image: "../assets/imgs/other/Stalin.JPG",
    title:
      "Preparing for a performance of Death of Stalin with the film with the BBCSO.",
  },

  {
    id: "1",
    image: "../assets/imgs/other/rob.JPG",
    title: "Catching up with old friend and NYCB trombone tutor Robb Tooley.",
  },
  {
    id: "1",
    image: "../assets/imgs/other/NYCB.JPG",
    title:
      "In rehearsal for a performance of Kirkfeld with the National Youth Concert Band.",
  },
  {
    id: "1",
    image: "../assets/imgs/other/Columbia1.jpg",
    title: "On tour in Colombia with Septura",
  },
  {
    id: "1",
    image: "../assets/imgs/other/Columbia2.jpg",
    title: "On tour in Colombia with Septura.",
  },
  {
    id: "1",
    image: "../assets/imgs/other/Columbia3.jpg",
    title: "On tour in Colombia with Septura.",
  },
  {
    id: "1",
    image: "../assets/imgs/other/Columbia4.jpg",
    title: "On tour in Colombia with Septura.",
  },
  {
    id: "1",
    image: "../assets/imgs/other/Columbia5.jpg",
    title: "On tour in Colombia with Septura.",
  },
  {
    id: "1",
    image: "../assets/imgs/other/Columbia6.jpg",
    title: "On tour in Colombia with Septura",
  },
  {
    id: "1",
    image: "../assets/imgs/other/judges_row.JPG",
    title:
      "Judges for the 2023 Citta di Porcia Trombone Competition, Pordenone, Italy. L to R: Jonas Bylund, Daniele Morandini, Inda Bonet, Fabrice Millischer, Andrea Bandini, Giampaolo Doro, Michel Becquet and Helen.",
  },
  {
    id: "1",
    image: "../assets/imgs/other/judges_table.JPG",
    title:
      "L to R: Fabrice Millischer, Jonas Bylund, Daniele Morandini, Andrea Bandini, Helen, Michel Becquet and Inda Bonet.",
  },

  {
    id: "1",
    image: "../assets/imgs/other/masterclass.JPG",
    title: "Masterclass at the Royal Birmingham Conservatoire",
  },

  {
    id: "2",
    image: "../assets/imgs/other/other2.jpeg",
    title: "Fanfare Brass for the Coronation of King Charles III",
  },
];

export const TRIO = [
  {
    id: "1",
    image: "../assets/imgs/trio/Helen Vollam Trio.jpeg",
    title: "Trio with Philip Cobb and Elizabeth Burley",
  },
  {
    id: "1",
    image: "../assets/imgs/trio/Trio Recital at GSMD.jpeg",
    title: "Trio Recital at GSMD",
  },
  {
    id: "1",
    image:
      "../assets/imgs/trio/Trio with friend and former BBCSO trumpet colleague Paul Cosh.jpeg",
    title: "Trio with friend and former BBCSO trumpet colleague Paul Cosh",
  },
  {
    id: "1",
    image:
      "../assets/imgs/trio/Trio performing at the Dora Stoutzker Hall at the RWCMD.jpeg",
    title: "Trio performing at the Dora Stoutzker Hall at the RWCMD",
  },
  {
    id: "1",
    image: "../assets/imgs/trio/Trio at the RWCMD.jpeg",
    title: "Trio at the RWCMD",
  },
  {
    id: "1",
    image: "../assets/imgs/trio/trio_trinity.JPG",
    title: "Trio with Philip Cobb and Elizabeth Burley",
  },
  {
    id: "1",
    image: "../assets/imgs/trio/trio_portrait.PNG",
    title: "Trio recital and masterclasses at Trinity Laban.",
  },
  {
    id: "1",
    image: "../assets/imgs/trio/Trinity_Masterclass.jpg",
    title: "Trio recital and masterclasses at Trinity Laban.",
  },
  {
    id: "1",
    image: "../assets/imgs/trio/Trinity_masterclass_1.jpg",
    title: "Trio recital and masterclasses at Trinity Laban.",
  },
  {
    id: "1",
    image: "../assets/imgs/trio/Trinity_Masterclass_2.jpg",
    title: "Trio recital and masterclasses at Trinity Laban.",
  },
  {
    id: "1",
    image: "../assets/imgs/trio/trio1.jpg",
    title:
      "Trio Recital at the Royal Birmingham Conservatoire with Philip Cobb and Elizabeth Burley.",
  },
  {
    id: "1",
    image: "../assets/imgs/trio/trio2.jpeg",
    title:
      "Trio Recital at the Royal Birmingham Conservatoire with Philip Cobb and Elizabeth Burley.",
  },
  {
    id: "1",
    image: "../assets/imgs/trio/trio3.JPG",
    title:
      "Trio Recital at the Royal Birmingham Conservatoire with Philip Cobb and Elizabeth Burley.",
  },
  {
    id: "1",
    image: "../assets/imgs/trio/trio_after.jpeg",
    title:
      "After the recital with composer Julia Simpson, Phil, Liz and Helen.",
  },
  {
    id: "1",
    image: "../assets/imgs/other/masterclass.JPG",
    title: "Masterclass at the Royal Birmingham Conservatoire",
  },
];

export const FIRST = [
  {
    id: "0",
    image: "../assets/imgs/other/ShellLSO_Final.JPG",
    title: "Shell/LSO Scholarship Final 1995",
  },
  {
    id: "1",
    image: "../assets/imgs/other/WithPJ.JPG",
    title: "With Philip Jones, 1995",
  },
];

export const TEACHING = [
  {
    title: "Teaching at Cheltenham Ladies' College",
    image: "../assets/imgs/teaching/cheltenham.jpeg",
  },
  {
    title: "Teaching at Cheltenham Ladies' College",
    image: "../assets/imgs/teaching/cheltenham2.jpeg",
  },
  { title: "", image: "../assets/imgs/teaching/teaching1.jpg" },
  { title: "", image: "../assets/imgs/teaching/teaching2.jpg" },
  { title: "", image: "../assets/imgs/teaching/teaching3.jpg" },
  { title: "", image: "../assets/imgs/teaching/teaching4.jpg" },
  { title: "", image: "../assets/imgs/teaching/teaching5.jpg" },
  { title: "", image: "../assets/imgs/teaching/teaching6.jpg" },
  { title: "", image: "../assets/imgs/teaching/teaching7.jpg" },
  { title: "", image: "../assets/imgs/teaching/teaching8.jpg" },
  { title: "", image: "../assets/imgs/teaching/teaching9.jpg" },
  { title: "", image: "../assets/imgs/teaching/teaching10.JPG" },
  { title: "", image: "../assets/imgs/teaching/teaching11.JPG" },
  { title: "", image: "../assets/imgs/teaching/teaching12.jpg" },
  {
    title: "Masterclass at the Cheltenham Ladies College",
    image: "../assets/imgs/teaching/teaching13.jpeg",
  },
  {
    title: "Masterclass at Trinity Laban Conservatoire of Music and Dance",
    image: "../assets/imgs/teaching/teaching14.jpeg",
  },
  {
    title: "Masterclass at Trinity Laban Conservatoire of Music and Dance",
    image: "../assets/imgs/teaching/teaching15.jpeg",
  },
];

export const BONES = [
  { title: "", image: "../assets/imgs/bones/BATQ_new.JPG" },
  { title: "", image: "../assets/imgs/bones/BATQ_BTF_2024.jpeg" },
  {
    title: "",
    image: "../assets/imgs/bones/BATQ at GALSI course at the RCM.jpeg",
  },
  {
    title: "",
    image: "../assets/imgs/bones/BATQ_front_cover_of_The_Trombonist.PNG",
  },
  { title: "", image: "../assets/imgs/bones/BATQ Christmas Concert 2024.jpeg" },
  { title: "", image: "../assets/imgs/bones/BATQ concert in Somerset.jpeg" },
  { title: "", image: "../assets/imgs/bones/bones1.jpg" },
  { title: "", image: "../assets/imgs/bones/bones2.jpg" },
  { title: "", image: "../assets/imgs/bones/bones3.jpg" },
  { title: "", image: "../assets/imgs/bones/BAcover.jpg" },
  { title: "", image: "../assets/imgs/bones/bones4.jpg" },
  { title: "", image: "../assets/imgs/bones/bones5.jpg" },
  { title: "", image: "../assets/imgs/bones/bones6.jpg" },
  { title: "", image: "../assets/imgs/bones/bones7.jpg" },
  { title: "", image: "../assets/imgs/bones/bones8.jpg" },
  {
    title:
      "BATQ with Amos Miller. Coaching at the Royal Birmingham Conservatoire",
    image: "../assets/imgs/bones/bones9.JPG",
  },
  { title: "", image: "../assets/imgs/bones/ww.jpg" },
];

export const MUSIC = [
  { title: "", image: "../assets/imgs/bones/music1.jpg" },
  { title: "", image: "../assets/imgs/bones/music2.jpg" },
  { title: "", image: "../assets/imgs/bones/music3.jpg" },
  { title: "", image: "../assets/imgs/bones/music4.jpg" },
  { title: "", image: "../assets/imgs/bones/music5.jpg" },
  { title: "", image: "../assets/imgs/bones/music6.jpg" },
];

export const COE = [
  {
    title: "COE trombones: Håkan Björkman, Helen Vollam & Nicholas Eastop",
    image: "../assets/imgs/coe/COE1.jpg",
  },
  {
    title:
      "COE trombones: Håkan Björkman, Bernard Haitink (guest star!), Helen Vollam",
    image: "../assets/imgs/coe/COE2.jpg",
  },
  {
    title: "COE trombones: Håkan Björkman, Helen Vollam & Nicholas Eastop",
    image: "../assets/imgs/coe/COE3.jpg",
  },
  { title: "", image: "../assets/imgs/coe/COE4.jpg" },
  { title: "", image: "../assets/imgs/coe/COE5.jpg" },
  { title: "", image: "../assets/imgs/coe/COE6.jpg" },
  { title: "", image: "../assets/imgs/coe/COE7.jpg" },
  {
    title:
      "COE and City of London Sinfonia trombones (Dan Jenkins, Amos Miller & Paul Lambert)",
    image: "../assets/imgs/coe/COE8.jpg",
  },
  { title: "featuring Karl Frisendahl", image: "../assets/imgs/coe/COE9.jpg" },
  {
    title: "featuring Jens, the CIE tuba player",
    image: "../assets/imgs/coe/COE10.jpg",
  },
  { title: "", image: "../assets/imgs/coe/COE11.jpg" },
  { title: "", image: "../assets/imgs/coe/COE12.jpg" },
  { title: "", image: "../assets/imgs/coe/COE13.jpg" },
  { title: "", image: "../assets/imgs/coe/COE14.JPG" },
  { title: "", image: "../assets/imgs/coe/COE15.JPG" },
  { title: "", image: "../assets/imgs/coe/COE16.jpeg" },
  {
    title: "With Håkan and Herbert Blomstedt",
    image: "../assets/imgs/coe/COE17.jpeg",
  },
  {
    title: "",
    image: "../assets/imgs/coe/COE BB.jpeg",
  },
];

export const SOLO = [
  {
    title: "In rehearsal with the NYCB of Kirkfeld.",
    image: "../assets/imgs/solo/kirkfeld.jpeg",
  },
  {
    title:
      "Performing the solo in George Crumb’s Star Child with the BBC Symphony Orchestra.  ",
    image: "../assets/imgs/solo/star_child.JPG",
  },
  {
    title:
      "Performing the solo in George Crumb’s Star Child with the BBC Symphony Orchestra.  ",
    image: "../assets/imgs/solo/star_child2.JPG",
  },
  {
    title:
      "Recital with Elizabeth Burley at the Royal Welsh College of Music and Drama",
    image: "../assets/imgs/solo/wales_solo.jpg",
  },
  {
    title:
      "Recital with Elizabeth Burley at the Royal Welsh College of Music and Drama",
    image: "../assets/imgs/solo/wales_solo2.jpg",
  },
  // {
  //   title: "Performing solo at Cheltenham Ladies' College",
  //   image: "../assets/imgs/solo/cheltenham_solo.jpeg",
  // },
  {
    id: "0",
    image: "../assets/imgs/Higgins/higgins1.jpg",
    title:
      "Performing Higgins Book of Miracles, a new trombone concerto, with the BBCSO",
  },
  {
    id: "1",
    image: "../assets/imgs/other/NYCB.JPG",
    title:
      "In rehearsal for a performance of Kirkfeld with the National Youth Concert Band.",
  },
  {
    id: "13",
    image: "../assets/imgs/bbc/BBC14.jpg",
    title:
      "Performing a duet with singer-songwriter Hak Baker at the Topping Out Ceremony, BBC's New Music Studio at East Bank, Stratford.",
  },
];
