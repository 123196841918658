<app-nav></app-nav>
<div class="content">
  <div class="col-md-5">
    <h1>'Book of Miracles'</h1>
    <p>World Premiere of a new trombone concerto by Gavin Higgins</p>
  </div>

  <div class="row">
    <div class="col-6">
      <video poster="../../assets/imgs/Higgins/Main.JPG" controls>
        <source src="../../assets/video/HigginsMusicOnly.mp4" />
      </video>
      <p>
        Helen premiered Gavin Higgins’ new trombone concerto ‘The Book of
        Miracles’ in February 2019 at the Barbican in London with the BBC
        Symphony Orchestra conducted by Alexander Vedernikov.
      </p>
      <small>Listen to excerpts from the live performance:</small>
      <audio controls>
        <source src="../../assets/audio/HigginsBookOfMiraclesOverview.mp3" />
      </audio>
    </div>
    <div class="col-6">
      <img src="../../assets/imgs/Higgins/higgins2.jpg" alt="" />
    </div>
  </div>
</div>

<div class="row notes">
  <div class="col-md-6">
    <p>
      Gavin writes: <br />
      The Book of Miracles is a recently discovered 16th Century German
      manuscript made up of 167 surviving sheets, depicting in vivid detail
      miraculous signs, natural catastrophes and Christian myths. Bookended with
      depictions of biblical events – including stories from the old Testament
      and the Book of Revelation – and overflowing with striking images of
      comets, fantastical beasts, miracles celestial apparitions and other
      astronomical events, the manuscript is both an exceptional and unique work
      of renaissance art but also a comprehensive record of natural and
      supernatural phenomenon from antiquity to middle of the 16th Century.
    </p>

    <p>
      The types of spectacular phenomenon recorded in this manuscript have
      inspired both dread and awe in many disparate cultures. Comets have been
      considered both omens of disaster and messages from the gods; eclipses
      were seen as portents of catastrophe. A pantheon of myths, stories and
      tales have been invented to explain these extraordinary events and,
      despite scientific evidence to the contrary, we are still inexorably drawn
      to stories of angry gods, immortal decapitated heads and wolves in a
      perpetual game of ‘cat and mouse’ with the sun. Ultimately human beings
      are storytellers and these myths tell us something about ourselves; they
      hold a mirror up to our own humanity, providing a medium for us to process
      things that are beyond our control and comprehension.
    </p>

    <p>
      This collection of visually arresting illustrations is steeped in European
      folklore – inextricable events are playfully depicted with chimerical and
      wondrous detail. These images are at once strikingly modern – existing
      somewhere outside of time – and yet inhabit a world of myth and
      superstition that are inevitably linked to Christian mythology.
    </p>
  </div>

  <div class="col-md-6">
    <p>
      There are three types of ‘celestial apparition’ that recur throughout the
      manuscript: <br />
      namely comets – whose sudden appearance in the night sky are here depicted
      as portentous omens; parhelia – caused by refractions of light passing
      through ice crystals in the air, the illustrations of which are some of
      the most impressive in the book; and both solar and lunar eclipses.
      Together they form perhaps the most striking and evocative images within
      the manuscript:
      <em
        >The illustrations of celestial apparitions are particularly fascinating
        for the modern viewer, since the vividness and care with which they are
        depicted manifest, an almost scientific interest that looks ahead to the
        future and seems strangely contradictory in the context of portents and
        miraculous signs.</em
      >
    </p>

    <p>
      The final 21 plates are concerned with the book of Revelation. Fantastical
      images of angel trumpeters, heavenly battles, and seven headed beasts are
      depicted with almost playful abandon.
    </p>

    <p>
      From these images I have drawn inspiration for the four movements of this
      concerto:
    </p>
    <ul>
      <li>I Comet</li>
      <li>II Parhelia</li>
      <li>III Eclipse (cadenza)</li>
      <li>IV Revelation</li>
    </ul>

    <p>
      <em>
        “… On the fourth day of the month of May, a terrible comet was seen in
        the sky… with many fiery flames falling from it. And after this there
        followed some strange things.”</em
      >
    </p>

    <p>
      Find out more about Gavin’s music at
      <a href="http://www.gavinhiggins.com"> www.gavinhiggins.com</a>
    </p>
  </div>
</div>

<div id="reviews">
  <h4>What the critics said:</h4>

  <div
    *ngFor="let review of reviews"
    [ngClass]="
      review.title === 'Higgins ‘Book of Miracles’, Trombone Concerto.'
        ? null
        : 'hidden'
    "
  >
    <p>
      <em>{{ review.content }}</em
      ><br /><small>{{ review.author }}</small>
    </p>
  </div>
</div>
<div id="interviews">
  <h4>Interviews</h4>
  <div>
    <p>
      Interview with Gavin and Helen from the
      <a href="https://www.britishtrombonesociety.org/">
        <em>British Trombone Society </em></a
      >magazine, Winter 2018:
    </p>

    <p>
      <em>
        When was this piece first conceived, and how has it come into
        fruition?</em
      >
    </p>

    <p>
      GH: One or two years ago I actually went to the BBC Symphony about writing
      a clarinet concerto, which they were sort of keen on, but then they
      realised they’d commissioned quite a lot of wind concertos of late. So
      they asked if there was anything else I would like to do, and I said there
      has been a trombone concerto in my head, and I’d already spoken to Helen a
      bit about this. They went for it immediately! So that’s how this concerto
      began life.
    </p>

    <p>
      <em
        >Could you tell us a bit about your compositional process and how you’ve
        been developing this concerto?</em
      >
    </p>

    <p>
      GH: I’ve written quite a lot of music for solo instrument and brass band,
      but this is the first concerto with orchestra I’ve written. So it’s been
      quite a challenge to write, actually. I knew I wanted it to be quite a
      substantial piece. I didn’t want it to be 10-15 minutes and over. It’s
      about 24 minutes, quite a big piece, quite epic.
    </p>

    <p>
      It’s not so much a person at the front who the orchestra sits behind
      having a dialogue. Helen is in the centre of the orchestra and the
      orchestra swirls around her throughout the piece.
    </p>

    <p>
      There’s normally an extra musical inspiration for my pieces. A little
      while ago I came across a recently discovered 16th century German
      illuminated manuscript called The Book of Miracles. It’s an incredible
      book that depicts various supernatural phenomena, starting in the Old
      Testament and moving through historical events to the time when it was
      written. The last part is depictions of revelations. They are quite
      striking images, because they look incredibly modern – vivid, colourful
      and quite playful in a way. It’s full of comets and eclipses, and things
      falling form the sky. When I first discovered the book, I knew I wanted to
      write a piece on it. I thought it was going to be the clarinet concerto,
      but the trombone being a declamatory instrument, I thought it would be the
      right thing to explore with this piece. It’s in four movements: a
      substantial first movement that is nine or ten minutes long, a slow second
      movement going into the third which is full of extended cadenza, and a
      fast and furious finale.
    </p>

    <p>
      <em>
        You mentioned you chatted with Helen at the Last Night of the Proms a
        few years ago. Since then, while you’ve been writing the piece, has
        there been much collaboration and exchange of ideas between you and
        Helen, or has she just let you get on with writing it?</em
      >
    </p>

    <p>
      GH: A lot actually; every time I write something, I send it to her to have
      a go at – I’m writing a piece for her and the BBC Symphony, so I want to
      make sure it’s a piece she’s happy playing. Certain players have things
      they enjoy doing and things they don’t enjoy doing. So there’s been quite
      a lot of back and forth, which is good as I think the first sketch I sent
      her was verging on impossible! (Which is fine, my feeling is to get it all
      down first and clear out what doesn’t need to be there later.) We met up a
      few times, and tried things out. I’m a brass player, but I’m not a
      trombone player. I know the slide positions, but I don’t really know what
      the actuality of doing some of these things in real time is, so it’s been
      quite useful.
    </p>

    <p>
      So often you’re sent off to do your thing and you hand it in at the end,
      which is quite a lonely process. But if you’re writing a concerto for a
      specific player, I think it’s important that you have a lot of contact
      with that person. Also, I think it puts Helen’s mind at rest, that I’m not
      just handing her a massive 24 minute concerto at the end of the process,
      and she’s actually getting to see it as it comes along.
    </p>

    <p>
      <em
        >What have you found are some of the opportunities with writing for the
        trombone and also some of the challenges?</em
      >
    </p>

    <p>
      GH: It has been quite a challenge actually, because when you think of a
      concerto you naturally think of extreme virtuosity. This piece is
      virtuosic, it’s quite a blow, there’s a lot going on, but it’s not the
      same type of virtuosity as on a violin or a clarinet. You have to readjust
      your head to the instrument, what it can do, what it sounds good doing,
      where the line is of what’s actually playable and what isn’t. Things like
      needing breaks and time to breathe and all the other things you have to
      take into consideration.
    </p>

    <p>
      <em
        >Have you used many extended techniques in the piece, or – in terms of
        techniques – is it quite conventional?</em
      >
    </p>

    <p>
      GH: There a few different mutes, and the end of the cadenza is sort of a
      glissando fantasy, full of glissandos and rips – but nothing too whacky!
      We played around with multi-phonics, but the music that I had in my head,
      and what needed to be done for it to work effectively didn’t quite match
      up, so we left that.
    </p>

    <p>
      <em>
        You’ve given us a good idea of what people can expect from this piece in
        terms of flavour and structure. What about the orchestral forces
        involved?</em
      >
    </p>

    <p>
      GH: It’s for full orchestra, triple winds, full brass; it does get quite
      big at times, but the middle section is slow, full of solo strings, with
      muted trombone over the top. In the first movement, while there’s a lot of
      activity, the harmonic language is quite static – it takes quite a long
      time for a chord to change. In the last movement however, they change
      basically every quaver or so.
    </p>

    <p><em>Is there anything else you want to say?</em></p>

    <p>
      GH: Helen’s such a classy player. At the very beginning she was adamant
      she wanted to be able to play everything, which is not to say ‘don’t make
      it hard’; she wanted to make sure every note was there. She wasn’t
      interested in bluffing, which is quite a nice thing to hear. So it’s nice
      to be working with someone who is very clear that what’s there is what she
      wants to play. It’s been a nice way into thinking about the trombone
      writing. I’m looking forward to it!
    </p>

    <p><em> We also spoke to Helen and asked for her point of view. </em></p>

    <p>
      <em
        >How do your preparations for the performance fit into your busy playing
        schedule?</em
      >
    </p>

    <p>
      HV: I am devoting time away from my orchestral schedule to prepare for
      this piece, as solo and orchestral styles don’t particularly compliment
      each other. In my experience, in solo playing the sound can get much
      brighter and more direct than is often desirable for symphony orchestra
      playing. So I allocate certain days and weeks to focus on the new
      concerto.
    </p>

    <p>
      <em
        >Are there any challenges you have come up against so far whilst
        preparing this piece?</em
      >
    </p>

    <p>
      HV: Gavin’s piece is full of challenges and I’m very excited about
      rehearsing and performing it with my lovely BBCSO colleagues and Alexander
      Vedernikov in February. I’m hoping to get a f inished part later this
      month, but since the summer we have been trying various excerpts and ideas
      of Gavin’s. It’s been fascinating to watch the piece evolve. Gavin is a
      very talented composer and I feel incredibly fortunate to be able to
      collaborate with him on this exciting addition to the solo trombone
      repertoire. He’s even given me some bars rest!
    </p>

    <p>
      <em>
        This piece will be a major addition to the trombone repertoire, how have
        you seen the solo repertoire develop over your career?</em
      >
    </p>

    <p>
      HV: There has been a steady flow of new tenor trombone pieces coming into
      the repertoire since I was at music college. We must of course thank
      Christian Lindberg, Joseph Alessi, Ian Bousfield and John Kenny for their
      huge influence and energy, but also more recently Jörgen van Rijen, Håkan
      Björkman, Katy Jones, Matthew Gee and Peter Moore for actively
      commissioning original works for trombone. And hopefully with three major
      new trombone concertos (MacMillan, Higgins and Casken) having been
      performed in the UK within the same concert season, the trombone concerto
      will become a less rare beast in concert programming.
    </p>
  </div>
</div>
