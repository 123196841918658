<app-nav></app-nav>
<div class="light-text-box solo-top">
  <div class="solo-portrait">
    <img class="hero-image" src="../assets/imgs/portrait1.jpg" alt="" />
  </div>
  <div class="solo-text">
    <div>
      <h1>Solo</h1>
    </div>
  </div>
</div>

<div class="solo-content">
  <div class="section-half text-center">
    <h3>The Book of Miracles</h3>

    <small>a new trombone concerto</small>

    <p class="description">
      Helen premiered Gavin Higgins’ new trombone concerto ‘The Book of
      Miracles’ in February 2019 at the Barbican in London with the BBC Symphony
      Orchestra conducted by Alexander Vedernikov.
    </p>

    <video poster="../../assets/imgs/Higgins/Main.JPG" controls>
      <source src="../../assets/video/HigginsMusicOnly.mp4" />
    </video>

    <a class="button next-button" routerLink="/higgins"> Read more </a>
  </div>
  <div class="gallery-container">
    <app-photoshow [data]="photos"></app-photoshow>
  </div>
  <div class="section-half text-center">
    <div class="solo-section"></div>
    <div class="section-half solo-section">
      <app-media-hero></app-media-hero>
    </div>
    <div class="solo-section">
      <h3>Solo Features with Bones Apart</h3>

      <div class="audio-container">
        <div class="audio-item" *ngFor="let track of audio">
          <div>
            <img class="audio-item-cover" src="{{ track.cover }}" alt="" />
            <h6 class="audio-item-title">{{ track.title }}</h6>
            <small>{{ track.info }}</small>
            <audio controls>
              <source src="{{ track.audio }}" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
