<app-nav></app-nav>

<div class="content">
  <div>
    <div class="hero">
      <img src="../../assets/imgs/trio/trio.jpeg" alt="" />
      <div class="light-text-box">
        <h1>Trio</h1>
        <h3>Piano Trio with Phil Cobb & Elizabeth Burley</h3>

        <a class="nav-link" routerLink="/contact">Contact me for bookings</a>
      </div>
      <div class="text-justify light-text-box">
        <p>
          This trio project was a long time in the planning, so during the
          pandemic in 2020 I finally put together some repertoire, combining
          arrangements with some new commissions. I wanted to create new
          repertoire, and build on the existing works for trumpet, trombone and
          piano in order to breathe new life into this brass chamber ensemble: a
          brass equivalent to the traditional piano trio. Alongside brand new
          commissions by Julia Simpson ‘Come Buy!’, Dan Jenkins ‘Exuberance &
          Hymn’ and Steen N. Hansen ‘Bamboo’, the group performs music by J.S.
          Bach, Handel, Brahms, Mahler, Gershwin and much more. The trio has
          performed recitals and given solo and chamber music masterclasses at
          the Royal Birmingham Conservatoire, Trinity Laban, Guildhall School of
          Music and Drama and Royal Welsh College of Music and Drama. We are
          very grateful for the support of Besson and Conn-Selmer instruments.
        </p>
      </div>
    </div>
    <div class="trio-content">
      <div class="trio-content-photos">
        <app-photoshow [data]="photos"></app-photoshow>
      </div>
      <div class="text-center trio-content-text">
        <div class="text-justify light-text-box">
          <h3>Reviews</h3>
          <h5>
            Dora Stoutzker Hall, RWCMD, Cardiff Friday 18 October 2024
            <br /><small>By Iwan Fox 4barsrest</small>
          </h5>
          <p>
            Helen Vollam (trombone)<br />
            Philip Cobb (trumpet)<br />
            Elizabeth Burley (piano)<br />
          </p>
          <p>
            "A recital performance of perfectly balanced contrasts from three
            musicians in musical synchronisation.
          </p>
          <p>
            Handel’s ‘Happy We’ from his opera
            <em> ‘Acis and Galatea’</em> summed up the ‘joys and charms’ felt in
            listening to this recital of balanced musical synchronisation.
            Opening with the duet for soprano and tenor voice, Philip Cobb and
            Helen Vollam sparred playfully off each other in pastoral
            counterpoint, never in tension – a feature added to throughout by
            the subtle accompaniment of pianist Elizabeth Burley. From then on
            it was an hour or so of cultured expositions: Dan Jenkins’ concise
            <em>‘Exuberance & Hymn’</em> with its brittle fanfare statements
            leading into a paean of contentment was balanced by the rich
            romanticism of Brahms <em>‘So lass uns wandern!’</em> that captured
            the old composer’s affectionate (if unfulfilled) longing for the
            ‘dark eyes’ of his pupil Elisabeth von Herzogenberg. The clever
            characterisation of Julia Simpson’s entrepreneurial market gardening
            goblin in <em>‘Come Buy!’</em> was a neat mix of the mischievous and
            the melancholic.
          </p>
          Definition of time and place came with the homage to the Hollywood
          compositional greats of Korngold, Williams and Newman in a wonderfully
          played triptych of
          <em>‘Dogberry & Verges’, ‘Born on the 4th July’</em> and ‘Street
          Scene’. Korngold’s character portrait of the two police constables
          from Shakespeare’s ‘Much Ado About Nothing’ captured their dogged
          seriousness and comedic ineptitude to a tee – Dixon of Dock Green
          meets Officer Dibble from Top Cat. The famous trumpet theme from
          Oliver Stone’s anti-war movie ached with the elegiac defiance of an
          1970s America of lost honour and needless sacrifice, whilst the
          contrast offered with a 1930s country of eternal urban optimism and
          the suave millionaire King Vidor fancies of Alfred Newman’s sublime
          film score was as delight. Peter Graham’s
          <em>‘Meditation’</em> offered a link to the brass band hinterland of
          Philip Cobb (a theme from the test-piece <em>‘Triumph of Time’</em>)
          as he led on cornet with glowing tenderness.
          <p>
            A superb recital closed with Chick Corea’s self-identifying
            <em>‘Armando’s Rhumba’</em> from his 1976 album‘My Spanish Heart’
            and the delightful encore of a Bach organ prelude."
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
