export const BBC = [
  {
    title: "Doctor Atomic",
    image: "../assets/imgs/CDs/CD4.jpg",
  },
  {
    title: "Sibelius Leminnkäinen Suite",
    image: "../assets/imgs/CDs/CD7.jpg",
  },
  {
    title: "Elgar The Music Makers, The Spirit of England",
    image: "../assets/imgs/CDs/CD6.jpg",
  },
  {
    title: "Vaughan Williams Symphonies",
    image: "../assets/imgs/CDs/CD19.JPG",
  },
  {
    title: "Finzi",
    image: "../assets/imgs/CDs/CD20.JPG",
  },
  {
    title: "Berlioz, Romeo & Juliet",
    image: "../assets/imgs/CDs/CD1.jpg",
  },
  {
    title: "Bliss Morning Heroes",
    image: "../assets/imgs/CDs/CD21.JPG",
  },
  {
    title: "Walton Violin Concerto",
    image: "../assets/imgs/CDs/CD8.jpg",
  },
  {
    title: "Teirkreis & Jubiläm",
    image: "../assets/imgs/CDs/CD2.jpg",
  },
  {
    title: "Bantock - Omar Khayyám",
    image: "../assets/imgs/CDs/CD5.jpg",
  },
  {
    title: "John Adams My Father Knew Charles Ives",
    image: "../assets/imgs/CDs/CD3.jpg",
  },
  {
    title: "Josef Suk: Prague A Summer's tale",
    image: "../assets/imgs/CDs/CD25.jpg",
  },
  {
    title: "Not The Messiah",
    image: "../assets/imgs/films/poster1.jpg",
  },
  {
    title: "The Bartered Bride, Smetana",
    image: "../assets/imgs/CDs/CD27.JPG",
  },
  {
    title: "Britten Sinfonia da Requiem",
    image: "../assets/imgs/CDs/CD28.jpg",
  },
  {
    title: "Hans Werner Henze: Works for Orchestra",
    image: "../assets/imgs/CDs/CD29.JPG",
  },
  {
    title: "Dora Pejačević, Piano Concerto and Symphony",
    image: "../assets/imgs/CDs/CD33.jpeg",
  },
  {
    title: "Vaughan Williams, Symphonies 6 & 8",
    image: "../assets/imgs/CDs/CD34.jpeg",
  },
  {
    title: "Berg Violin Concert, 3 Orchestral Pieces & Piano Sonata",
    image: "../assets/imgs/CDs/CD36.jpg",
  },
  {
    title: "Paul Weller, An Orchestral Songbook",
    image: "../assets/imgs/CDs/CD35.JPG",
  },
  {
    title: "Berlioz Te Deum",
    image: "../assets/imgs/CDs/CD34.JPG",
  },
  {
    title: "Alpine Symphony",
    image: "../assets/imgs/CDs/CD37.JPG",
  },
  {
    title: "The Rite of Spring",
    image: "../assets/imgs/CDs/CD38.JPG",
  },
];

export const BONES = [
  {
    title: "All We Want for Christmas",
    image: "../assets/imgs/CDs/CDBA2.jpg",
  },
  {
    title: "Bones Apart: Ten",
    image: "../assets/imgs/CDs/CDBA1.jpg",
  },
  {
    title: "Four4Four",
    image: "../assets/imgs/CDs/CDBA4.jpg",
  },
  {
    title: "Enigma",
    image: "../assets/imgs/CDs/CDBA3.jpg",
  },
];

export const COE = [
  {
    title: "COE Mendelssohn Symphonies",
    image: "../assets/imgs/CDs/CD16.jpg",
  },
  {
    title: "COE: Mozart Requiem",
    image: "../assets/imgs/CDs/CD17.jpg",
  },
  {
    title: "COE: Dvorak",
    image: "../assets/imgs/CDs/CD11.jpg",
  },
  {
    title: "COE: Brahms Symphonies",
    image: "../assets/imgs/CDs/CD15.jpg",
  },
  {
    title: "COE: The Bartered Bride with Nicholas Harnoncourt",
    image: "../assets/imgs/CDs/CD24.jpg",
  },
  {
    title: "COE: Schumann Symphonies",
    image: "../assets/imgs/CDs/CD12.jpg",
  },
  {
    title: "The Magic Flute",
    image: "../assets/imgs/films/poster18.jpg",
  },
  {
    title: "Haydn, Mozart, Betthoven, Brahms",
    image: "../assets/imgs/CDs/CD32.jpeg",
  },
  {
    title: "Brahms Symphonies",
    image: "../assets/imgs/CDs/CD39.JPG",
  },
];

export const OTHER = [
  {
    title: "Korngold/Previn: The Sea Hawk",
    image: "../assets/imgs/CDs/CD30.JPG",
  },
  {
    title: "Bryn",
    image: "../assets/imgs/CDs/CD9.jpg",
  },
  {
    title: "Shostakovich 11/Rostrapovich, LSO",
    image: "../assets/imgs/CDs/CD23.jpg",
  },
  {
    title: "American Classics, LSO",
    image: "../assets/imgs/CDs/CD22.jpg",
  },
  {
    title: "LSO: Elgar Symphony 3",
    image: "../assets/imgs/CDs/CD10.jpg",
  },
  {
    title: "LSO: Berlioz - Damnation of Faust",
    image: "../assets/imgs/CDs/CD14.jpg",
  },
  {
    title: "Natalie Cole",
    image: "../assets/imgs/CDs/CD18.jpg",
  },

  {
    title: "Brahms & Bruckner Motets",
    image: "../assets/imgs/CDs/CD13.jpg",
  },
  {
    title: "LSO: Berlioz, Les Troyens",
    image: "../assets/imgs/CDs/CD26.jpg",
  },
  {
    title: "Music for Chris",
    image: "../assets/imgs/CDs/CD31.JPG",
  },
  {
    title: "Shostakovich 6 & 15",
    image: "../assets/imgs/CDs/CD37.jpeg",
  },
];
