import { Component, OnInit } from "@angular/core";
import { AUDIO } from "../content/audio";
import { SOLO } from "../content/photos";

@Component({
  selector: "app-solo",
  templateUrl: "./solo.component.html",
  styleUrls: ["./solo.component.css"],
})
export class SoloComponent implements OnInit {
  audio = [];
  photos = SOLO;
  constructor() {}

  ngOnInit(): void {
    const titles = [
      "Book of Miracles",
      "My Funny Valentine",
      "Habañera",
      "Seguidille",
    ];
    this.audio = AUDIO.filter((track) => titles.includes(track.title));
  }
}
